import { useState, useEffect } from "react";

export const Header = () => {
	const [onLoad, setOnLoad] = useState(false);
	const [theme, setTheme] = useState();

	useEffect(() => {
		setOnLoad(true);
	}, []);

	const getTheme = () => {
		const theme = localStorage.getItem("theme");
		if (theme) {
			return theme;
		} else return "light";
	};

	useEffect(() => {
		const currentTheme = getTheme();
		if (!currentTheme) {
			localStorage.setItem("theme", "light");
			setTheme("light");
		} else setTheme(currentTheme);
	}, []);

	useEffect(() => {
		if (!theme) return;
		if (window !== undefined) {
			localStorage.setItem("theme", theme);
			if (theme === "dark") {
				document.documentElement.className = "dark";
			} else {
				document.documentElement.className = "";
			}
			document.documentElement.setAttribute("data-theme", theme);
		}
	}, [theme]);

	return (
		<>
			<div className="sticky top-0 z-50 flex flex-col min-h-[10vh] w-full justify-center items-center bg-opacity-80 backdrop-blur-sm bg-base-100 dark:bg-transparent text-black dark:text-white">
				<div
					className="main 
          flex justify-center items-center flex-col gap-1 md:gap-0 md:flex-row
          w-full my-auto
          text-4xl
          transition"
				>
					<div className="text-2xl md:text-4xl md:w-3/6">Choi Jin Seng</div>
					<div
						className="nav 
          flex justify-between items-center flex-wrap
          w-full md:w-3/6 md:ml-auto md:my-2 md:py-0
          text-base md:text-2xl"
					>
						<a className="about navLink" href="#about">
							About
						</a>
						<a className="skills navLink" href="#skills">
							Skills
						</a>
						<a className="links navLink" href="#links">
							Links
						</a>
						<a className="contact navLink" href="#contact">
							Contact
						</a>
						<label className="swap swap-rotate">
							{theme !== undefined && (
								<input
									type="checkbox"
									defaultChecked={theme === "light" ? false : true}
									onChange={(e) => {
										setTheme(e.target.checked === true ? "dark" : "light");
									}}
								/>
							)}

							<svg
								className="swap-on fill-current w-7 h-7"
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 24 24"
							>
								<path d="M5.64,17l-.71.71a1,1,0,0,0,0,1.41,1,1,0,0,0,1.41,0l.71-.71A1,1,0,0,0,5.64,17ZM5,12a1,1,0,0,0-1-1H3a1,1,0,0,0,0,2H4A1,1,0,0,0,5,12Zm7-7a1,1,0,0,0,1-1V3a1,1,0,0,0-2,0V4A1,1,0,0,0,12,5ZM5.64,7.05a1,1,0,0,0,.7.29,1,1,0,0,0,.71-.29,1,1,0,0,0,0-1.41l-.71-.71A1,1,0,0,0,4.93,6.34Zm12,.29a1,1,0,0,0,.7-.29l.71-.71a1,1,0,1,0-1.41-1.41L17,5.64a1,1,0,0,0,0,1.41A1,1,0,0,0,17.66,7.34ZM21,11H20a1,1,0,0,0,0,2h1a1,1,0,0,0,0-2Zm-9,8a1,1,0,0,0-1,1v1a1,1,0,0,0,2,0V20A1,1,0,0,0,12,19ZM18.36,17A1,1,0,0,0,17,18.36l.71.71a1,1,0,0,0,1.41,0,1,1,0,0,0,0-1.41ZM12,6.5A5.5,5.5,0,1,0,17.5,12,5.51,5.51,0,0,0,12,6.5Zm0,9A3.5,3.5,0,1,1,15.5,12,3.5,3.5,0,0,1,12,15.5Z" />
							</svg>

							<svg
								className="swap-off fill-current w-7 h-7"
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 24 24"
							>
								<path d="M21.64,13a1,1,0,0,0-1.05-.14,8.05,8.05,0,0,1-3.37.73A8.15,8.15,0,0,1,9.08,5.49a8.59,8.59,0,0,1,.25-2A1,1,0,0,0,8,2.36,10.14,10.14,0,1,0,22,14.05,1,1,0,0,0,21.64,13Zm-9.5,6.69A8.14,8.14,0,0,1,7.08,5.22v.27A10.15,10.15,0,0,0,17.22,15.63a9.79,9.79,0,0,0,2.1-.22A8.11,8.11,0,0,1,12.14,19.73Z" />
							</svg>
						</label>
					</div>
				</div>
				<div
					className={`divide
        ${onLoad ? "w-full" : "w-0"}
        border-b-[1px] border-black dark:border-white 
        transition`}
					style={{
						transition: "width 0.75s",
						transitionTimingFunction: "cubic-bezier(0.1, 0.7, 1.0, 0.1)",
					}}
				></div>
			</div>
		</>
	);
};
