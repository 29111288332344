import { Header } from "../components/header";
import heroImage from "./../assets/arif-riyanto-G1N9kDHqBrQ-unsplash.jpg";
import aboutImage from "./../assets/linkedin-sales-solutions-0QvTyp0gH3A-unsplash.jpg";
import flyingshurikencom from "./../assets/flyingshurikencom.png";
import peepooshop from "./../assets/peepooshop.png";
import githubprofile from "./../assets/githubprofile.png";
import logo from "./../assets/logo512.png";
import { Helmet } from "react-helmet";

function App() {
	// const [theme, setTheme] = useState();

	// useEffect(() => {
	// 	const currentTheme = getTheme();
	// 	if (!currentTheme) {
	// 		localStorage.setItem("theme", "light");
	// 		setTheme("light");
	// 	} else setTheme(currentTheme);
	// }, []);

	return (
		<div className="App bg-white dark:bg-gray-900">
			<Helmet>
				<meta charSet="utf-8" />
				<title>Choi Jin Seng</title>
				<meta name="description" content="My portfolio website!" />
				<link rel="icon" type="image/x-icon" href={logo} />
			</Helmet>
			<script
				src="https://s.pageclip.co/v1/pageclip.js"
				charset="utf-8"
				defer
			></script>
			<link
				rel="stylesheet"
				href="https://s.pageclip.co/v1/pageclip.css"
				media="screen"
			></link>
			<div className="main px-6 md:px-16 lg:px-32 ">
				<Header />
				<div className="text-gray-600 body-font dark:text-gray-400 dark:bg-gray-900 min-h-[90vh] flex items-center mt-5 md:mt-0">
					<div className="container h-full mx-auto flex md:flex-row flex-col items-center">
						<div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
							<h1 className="title-font sm:text-4xl md:text-3xl text-2xl mb-4 font-medium text-gray-900 dark:text-white">
								Hey! I am Choi Jin Seng.{" "}
								<br className="hidden lg:inline-block" />A Full Stack Developer
							</h1>
							<p className="mb-8 leading-relaxed sm:text-xl text-lg font-semibold">
								I am a Full Stack Developer with a passion for building web
								apps. I just finish my SPM exam and currently waiting for the
								result! I am also a developer who is always looking for new
								opportunities to learn and grow. I am currently looking for a
								part-time position as a Software Engineer. I am also open to
								freelance opportunities!
							</p>
							<div className="flex justify-center gap-8">
								<a className="link text-xl no-underline navLink" href="#about">
									More About Me
								</a>
								<a
									className="link text-xl no-underline navLink"
									href="#contact"
								>
									Contact Me
								</a>
							</div>
						</div>
						<div className="lg:w-1/2 md:w-1/2 w-full overflow-hidden">
							<img
								className="object-cover h-96 w-full"
								alt="hero"
								src={heroImage}
							/>
							<p className="text-sm">
								Photo from{" "}
								<a href="https://unsplash.com/@arifriyanto">Arif Riyanto</a>
							</p>
						</div>
					</div>
				</div>
				<div className="divide h-[10vh]" id="about"></div>
				<div className="text-gray-600 body-font dark:text-gray-400 dark:bg-gray-900 min-h-[90vh] flex items-center">
					<div className="container mx-auto flex flex-wrap">
						<div className="flex flex-wrap w-full">
							<div className="lg:w-2/5 md:w-1/2 w-full overflow-hidden md:py-6 md:mt-0 mt-12">
								<img
									className="h-96 w-full object-cover rounded-lg "
									src={aboutImage}
									alt="aboutImage"
								/>
								<p className="text-sm">
									Photo from{" "}
									<a href="https://unsplash.com/@linkedinsalesnavigator">
										LinkedIn Sales Solutions
									</a>
								</p>
							</div>

							<div className="lg:w-3/5 md:w-1/2 md:pl-10 md:py-6 md:mt-0 mt-12">
								<div className="flex relative pb-12">
									<div className="h-full w-10 absolute inset-0 flex items-center justify-center">
										<div className="h-full w-1 bg-gray-200 dark:bg-gray-800 pointer-events-none"></div>
									</div>
									<div className="flex-shrink-0 w-10 h-10 rounded-full bg-indigo-500 inline-flex items-center justify-center text-white relative z-10">
										<svg
											fill="none"
											stroke="currentColor"
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="2"
											className="w-5 h-5"
											viewBox="0 0 24 24"
										>
											<path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
										</svg>
									</div>
									<div className="flex-grow pl-4">
										<h2 className="title-font text-sm text-gray-900 dark:text-white font-bold mb-1 tracking-wider">
											Form 1 (2018)
										</h2>
										<p className="leading-relaxed">
											Started learning basics of Computer Science at school. Got
											to know basics of HTML and CSS.
										</p>
									</div>
								</div>
								<div className="flex relative pb-12">
									<div className="h-full w-10 absolute inset-0 flex items-center justify-center">
										<div className="h-full w-1 bg-gray-200 dark:bg-gray-800 pointer-events-none"></div>
									</div>
									<div className="flex-shrink-0 w-10 h-10 rounded-full bg-indigo-500 inline-flex items-center justify-center text-white relative z-10">
										<svg
											fill="none"
											stroke="currentColor"
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="2"
											className="w-5 h-5"
											viewBox="0 0 24 24"
										>
											<path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
										</svg>
									</div>
									<div className="flex-grow pl-4">
										<h2 className="title-font text-sm text-gray-900 dark:text-white font-bold mb-1 tracking-wider">
											Form 2 (2019)
										</h2>
										<p className="leading-relaxed">
											Learned to code in Python based on pseudocode and
											flowchart. Manage to create a simple game using Python
											with Pygame.
										</p>
									</div>
								</div>
								<div className="flex relative pb-12">
									<div className="h-full w-10 absolute inset-0 flex items-center justify-center">
										<div className="h-full w-1 bg-gray-200 dark:bg-gray-800 pointer-events-none"></div>
									</div>
									<div className="flex-shrink-0 w-10 h-10 rounded-full bg-indigo-500 inline-flex items-center justify-center text-white relative z-10">
										<svg
											fill="none"
											stroke="currentColor"
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="2"
											className="w-5 h-5"
											viewBox="0 0 24 24"
										>
											<path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
											<circle cx="12" cy="7" r="4"></circle>
										</svg>
									</div>
									<div className="flex-grow pl-4">
										<h2 className="title-font text-sm text-gray-900 dark:text-white font-bold mb-1 tracking-wider">
											Form 4 (2021)
										</h2>
										<p className="leading-relaxed">
											Started to build a database driven web application using
											PHP and MySQL. Learned about UI/UX design and database
											design.
										</p>
									</div>
								</div>
								<div className="flex relative">
									<div className="flex-shrink-0 w-10 h-10 rounded-full bg-indigo-500 inline-flex items-center justify-center text-white relative z-10">
										<svg
											fill="none"
											stroke="currentColor"
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="2"
											className="w-5 h-5"
											viewBox="0 0 24 24"
										>
											<path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
											<path d="M22 4L12 14.01l-3-3"></path>
										</svg>
									</div>
									<div className="flex-grow pl-4">
										<h2 className="title-font text-sm text-gray-900 dark:text-white font-bold mb-1 tracking-wider">
											Form 5 (2022)
										</h2>
										<p className="leading-relaxed">
											Started working as a Frontend Developer in a Blockchain
											company that is based in Malaysia. Got to know about
											industry standard working environment and how to work in a
											team.
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="divide h-[10vh]" id="skills"></div>
				<div className="text-gray-600 body-font dark:text-gray-400 dark:bg-gray-900 min-h-[90vh] flex items-center">
					<div className="container px-5 mx-auto">
						<div className="text-center mb-20">
							<h1 className="sm:text-3xl text-2xl font-medium text-center title-font text-gray-900 dark:text-white mb-4">
								Skills &amp; Tools
							</h1>
						</div>
						<div className="flex flex-wrap -m-4">
							{[
								{
									name: "Coding Languages",
									items: [
										"TypeSript",
										"JavaScript",
										"HTML",
										"CSS",
										"SQL",
										"Python",
										"Java",
										"Go",
									],
								},
								{
									name: "Libraries & Frameworks",
									items: [
										"ReactJS",
										"NextJS",
										"TailwindCSS",
										"DiscordJS/PY",
										"TelegramBot",
										"Node.js",
										"Express.js",
										"Flask",
									],
								},
								{
									name: "Other Skills",
									items: ["AWS", "Azure", "Git", "Linux", "Vercel", "Github"],
								},
							].map((skill, index) => (
								<div
									className="p-4 lg:w-1/3 sm:w-1/2 w-full mx-auto"
									key={index}
								>
									<h2 className="title-font tracking-widest text-gray-900 dark:text-white font-extrabold mb-4 text-sm text-center sm:text-left flex justify-center items-center">
										{skill.name}
									</h2>
									<nav className="flex flex-col sm:items-start sm:text-left text-center items-center mx-auto space-y-2 w-fit">
										{skill.items.map((item, index) => (
											<div className="font-bold" key={index}>
												<span className="bg-indigo-100 text-indigo-500 dark:bg-gray-800 dark:text-indigo-400 w-4 h-4 mr-2 rounded-full inline-flex items-center justify-center">
													<svg
														fill="none"
														stroke="currentColor"
														strokeLinecap="round"
														strokeLinejoin="round"
														strokeWidth="3"
														className="w-3 h-3"
														viewBox="0 0 24 24"
													>
														<path d="M20 6L9 17l-5-5"></path>
													</svg>
												</span>
												{item}
											</div>
										))}
									</nav>
								</div>
							))}
						</div>
					</div>
				</div>
				<div className="divide h-[10vh]" id="links"></div>
				<div className="text-gray-600 body-font dark:text-gray-400 dark:bg-gray-900 min-h-[90vh] flex items-center">
					<div className="container px-5 mx-auto">
						<div className="flex flex-wrap -m-4">
							<div className="p-4 md:w-1/3">
								<div className="h-full border-2 border-gray-200 border-opacity-60 dark:border-gray-800 rounded-lg overflow-hidden">
									<img
										className="lg:h-48 md:h-36 w-full object-cover object-center"
										src={flyingshurikencom}
										alt="flyingshurikencom"
									/>
									<div className="p-6">
										<h2 className="tracking-widest text-xs title-font font-medium text-gray-500 mb-1">
											Portfolio
										</h2>
										<h1 className="title-font text-lg font-extrabold text-gray-900 dark:text-white mb-3">
											Flyingshuriken.com
										</h1>
										<p className="leading-relaxed mb-3 font-bold">
											Portfolio website that I made to showcase my projects and
											my skills. Made with ReactJS and TailwindCSS.
										</p>
										<div className="flex items-center flex-wrap ">
											<a
												className="text-indigo-400 inline-flex items-center md:mb-2 lg:mb-0"
												href="/"
											>
												Learn More
												<svg
													className="w-4 h-4 ml-2"
													viewBox="0 0 24 24"
													stroke="currentColor"
													strokeWidth="2"
													fill="none"
													strokeLinecap="round"
													strokeLinejoin="round"
												>
													<path d="M5 12h14"></path>
													<path d="M12 5l7 7-7 7"></path>
												</svg>
											</a>
										</div>
									</div>
								</div>
							</div>
							<div className="p-4 md:w-1/3">
								<div className="h-full border-2 border-gray-200 border-opacity-60 dark:border-gray-800 rounded-lg overflow-hidden">
									<img
										className="lg:h-48 md:h-36 w-full object-cover object-center"
										src={peepooshop}
										alt="peepooshop"
									/>
									<div className="p-6">
										<h2 className="tracking-widest text-xs title-font font-medium text-gray-500 mb-1">
											eCommerce
										</h2>
										<h1 className="title-font text-lg font-extrabold text-gray-900 dark:text-white mb-3">
											Peepoo Shop
										</h1>
										<p className="leading-relaxed mb-3 font-bold">
											A website I run that offer group orders for KPOP merch and
											Anime goods, and a lot more!
										</p>
										<div className="flex items-center flex-wrap">
											<a
												className="text-indigo-400 inline-flex items-center md:mb-2 lg:mb-0"
												href="https://peepooshop.com/"
												target="_blank"
												rel="noreferrer"
											>
												Learn More
												<svg
													className="w-4 h-4 ml-2"
													viewBox="0 0 24 24"
													stroke="currentColor"
													strokeWidth="2"
													fill="none"
													strokeLinecap="round"
													strokeLinejoin="round"
												>
													<path d="M5 12h14"></path>
													<path d="M12 5l7 7-7 7"></path>
												</svg>
											</a>
										</div>
									</div>
								</div>
							</div>
							<div className="p-4 md:w-1/3">
								<div className="h-full border-2 border-gray-200 border-opacity-60 dark:border-gray-800 rounded-lg overflow-hidden">
									<img
										className="lg:h-48 md:h-36 w-full object-cover object-center"
										src={githubprofile}
										alt="githubprofile"
									/>
									<div className="p-6">
										<h2 className="tracking-widest text-xs title-font font-medium text-gray-500 mb-1">
											Profile
										</h2>
										<h1 className="title-font text-lg font-extrabold text-gray-900 dark:text-white mb-3">
											Github Profile
										</h1>
										<p className="leading-relaxed mb-3 font-bold">
											My github profile, where I upload my projects and my code!
										</p>
										<div className="flex items-center flex-wrap ">
											<a
												className="text-indigo-400 inline-flex items-center md:mb-2 lg:mb-0"
												href="https://github.flyingshuriken.com"
												target="_blank"
												rel="noreferrer"
											>
												Learn More
												<svg
													className="w-4 h-4 ml-2"
													viewBox="0 0 24 24"
													stroke="currentColor"
													strokeWidth="2"
													fill="none"
													strokeLinecap="round"
													strokeLinejoin="round"
												>
													<path d="M5 12h14"></path>
													<path d="M12 5l7 7-7 7"></path>
												</svg>
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="divide h-[10vh]" id="contact"></div>
				<div className="text-gray-600 body-font dark:text-gray-400 dark:bg-gray-900 min-h-[90vh] flex items-center">
					<div className="container px-5 mx-auto pb-3">
						<div className="flex flex-col text-center w-full">
							<h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900 dark:text-white">
								Contact Me
							</h1>
						</div>
						<div className="lg:w-1/2 md:w-2/3 mx-auto">
							<div className="flex flex-wrap -m-2">
								<form
									action="https://send.pageclip.co/wnnCuvmz2nMZtF2hmNgdxr29b3VX5mxt/contact-form"
									className="pageclip-form flex flex-wrap -m-2"
									method="post"
								>
									<div className="p-2 w-1/2">
										<div className="relative">
											<label
												for="name"
												className="leading-7 text-sm text-gray-600 dark:text-gray-400"
											>
												Name
											</label>
											<input
												type="text"
												id="name"
												name="name"
												className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 dark:bg-gray-800 dark:bg-opacity-40 dark:border-gray-700 dark:focus:border-indigo-500 dark:focus:bg-gray-900 dark:focus:ring-indigo-900 dark:text-gray-100 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
											/>
										</div>
									</div>
									<div className="p-2 w-1/2">
										<div className="relative">
											<label
												for="email"
												className="leading-7 text-sm text-gray-600 dark:text-gray-400"
											>
												Email
											</label>
											<input
												type="email"
												id="email"
												name="email"
												className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 dark:bg-gray-800 dark:bg-opacity-40 dark:border-gray-700 dark:focus:border-indigo-500 dark:focus:bg-gray-900 dark:focus:ring-indigo-900 dark:text-gray-100 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
											/>
										</div>
									</div>
									<div className="p-2 w-full">
										<div className="relative">
											<label
												for="message"
												className="leading-7 text-sm text-gray-600 dark:text-gray-400"
											>
												Message
											</label>
											<textarea
												id="message"
												name="message"
												className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 dark:bg-gray-800 dark:bg-opacity-40 dark:border-gray-700 dark:focus:border-indigo-500 dark:focus:bg-gray-900 dark:focus:ring-indigo-900 dark:text-gray-100 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
											></textarea>
										</div>
									</div>
									<div className="p-2 w-full">
										<button className="flex mx-auto text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg">
											Send
										</button>
									</div>
									<div className="p-2 w-full py-8 mt-16 border-t border-gray-200 dark:border-gray-800 text-center flex flex-col justify-center items-center gap-3">
										<a
											className="text-indigo-400"
											href="mailto:mail@flyingshuriken.com"
										>
											mail@flyingshuriken.com
										</a>
										<span className="flex gap-6">
											<a
												className="text-gray-500"
												href="https://twitter.com/ShurikenFlying"
												target="_blank"
												rel="noreferrer"
											>
												<svg
													fill="currentColor"
													strokeLinecap="round"
													strokeLinejoin="round"
													strokeWidth="2"
													className="w-5 h-5"
													viewBox="0 0 24 24"
												>
													<path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
												</svg>
											</a>
											<a
												className="text-gray-500"
												href="https://www.instagram.com/choi.js_/"
												target="_blank"
												rel="noreferrer"
											>
												<svg
													fill="none"
													stroke="currentColor"
													strokeLinecap="round"
													strokeLinejoin="round"
													strokeWidth="2"
													className="w-5 h-5"
													viewBox="0 0 24 24"
												>
													<rect
														width="20"
														height="20"
														x="2"
														y="2"
														rx="5"
														ry="5"
													></rect>
													<path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
												</svg>
											</a>
											<a
												className="text-gray-500"
												href="https://github.flyingshuriken.com"
												target="_blank"
												rel="noreferrer"
											>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													class="icon icon-tabler icon-tabler-brand-github"
													width="24"
													height="24"
													viewBox="0 0 24 24"
													strokeWidth="2"
													stroke="currentColor"
													fill="none"
													strokeLinecap="round"
													strokeLinejoin="round"
													className="w-5 h-5"
												>
													<path
														stroke="none"
														d="M0 0h24v24H0z"
														fill="none"
													></path>
													<path d="M9 19c-4.3 1.4 -4.3 -2.5 -6 -3m12 5v-3.5c0 -1 .1 -1.4 -.5 -2c2.8 -.3 5.5 -1.4 5.5 -6a4.6 4.6 0 0 0 -1.3 -3.2a4.2 4.2 0 0 0 -.1 -3.2s-1.1 -.3 -3.5 1.3a12.3 12.3 0 0 0 -6.2 0c-2.4 -1.6 -3.5 -1.3 -3.5 -1.3a4.2 4.2 0 0 0 -.1 3.2a4.6 4.6 0 0 0 -1.3 3.2c0 4.6 2.7 5.7 5.5 6c-.6 .6 -.6 1.2 -.5 2v3.5"></path>
												</svg>
											</a>
										</span>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default App;
